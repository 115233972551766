// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-designer-js": () => import("./../../../src/pages/online-designer.js" /* webpackChunkName: "component---src-pages-online-designer-js" */),
  "component---src-pages-order-form-js": () => import("./../../../src/pages/order-form.js" /* webpackChunkName: "component---src-pages-order-form-js" */),
  "component---src-pages-price-list-js": () => import("./../../../src/pages/price-list.js" /* webpackChunkName: "component---src-pages-price-list-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

